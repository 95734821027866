import { Injectable } from '@angular/core';
import { observable, Observable, Subscriber } from 'rxjs';
import {io} from 'socket.io-client';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  socket: any;
  //readonly uri: string = 'http://localhost:9004';
  readonly uri: string = 'https://servicedesk.falconitracking.com:8080';
  uname:String = this.LocalStorage.retrieve('user');
  token:String = this.LocalStorage.retrieve('token');

  constructor(private LocalStorage : LocalStorageService) { 
    //this.socket = io.io(this.uri);
  }

  setupSocketConnection() {
    this.socket = io(this.uri,{transports: ['websocket']});
  }

  listen(eventName : string){
    return new Observable((subscriber)=>{
      this.socket.on(eventName, (data)=>{
        //console.log(data["value"]);
        subscriber.next(data);
      });
    });
  }

  emit(eventName : string, data : any){
    this.socket.emit(eventName, data);
  }

  DiconnectSocket(){
    this.socket.disconnect();
  }
}
