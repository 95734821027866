import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import { NavbarComponent } from '../../shared/navbar/navbar.component';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent, PlatformLocation } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { LocalStorageService } from 'ngx-webstorage';
import { WebSocketService } from 'app/Services/web-socket.service';
import * as webNotification from 'simple-web-notification';

declare var $:any;


@Component({
    selector: 'app-layout',
    templateUrl: './admin-layout.component.html'
})

export class AdminLayoutComponent implements OnInit {
    private _router: Subscription;
    // url: string;
     url: string;
     location: Location;
     private lastPoppedUrl: string;
       private yScrollStack: number[] = [];
    @ViewChild('sidebar', {static: false}) sidebar;
    @ViewChild(NavbarComponent, {static: false}) navbar: NavbarComponent;
    globalDepartments:string = this.LocalStorage.retrieve('globaldeps');
    uname:String = this.LocalStorage.retrieve('user');

    constructor( private router: Router, location:Location, private LocalStorage : LocalStorageService, private webSocketService : WebSocketService ) {
      this.location = location;
    }
    ngOnInit() {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      this.location.subscribe((ev:PopStateEvent) => {
          this.lastPoppedUrl = ev.url;
      });
       this.router.events.subscribe((event:any) => {
          if (event instanceof NavigationStart) {
             if (event.url != this.lastPoppedUrl)
                 this.yScrollStack.push(window.scrollY);
         } else if (event instanceof NavigationEnd) {
             if (event.url == this.lastPoppedUrl) {
                 this.lastPoppedUrl = undefined;
                 window.scrollTo(0, this.yScrollStack.pop());
             }
             else
                 window.scrollTo(0, 0);
         }
      });
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
           elemMainPanel.scrollTop = 0;
           elemSidebar.scrollTop = 0;
      });
      const html = document.getElementsByTagName('html')[0];
      if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
          let ps = new PerfectScrollbar(elemMainPanel);
          ps = new PerfectScrollbar(elemSidebar);
          html.classList.add('perfect-scrollbar-on');
      }
      else {
          html.classList.add('perfect-scrollbar-off');
      }
      this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
        this.navbar.sidebarClose();
      });

      //CUSTOM
      setTimeout(()=>{
        console.log("Setting Up Socket");
        this.webSocketService.setupSocketConnection();
        this.webSocketService.emit('Client', this.uname);
        this.webSocketService.listen('messages').subscribe((msg:any)=>{
          if(msg.data == this.uname){
            this.showNotification('bottom','right',msg.value, 'info');
              webNotification.showNotification('ServiceDesk Notification',{
                body: msg.value,
                icon: 'assets/img/favicon.ico',
                autoClose: false
              });
            return;
          }
          var dep = this.globalDepartments.split("|")
            for(var i = 0; i < dep.length; i++){
              if(msg.data == dep[i]){
                this.showNotification('bottom','right',msg.value, 'info');
                  webNotification.showNotification('ServiceDesk Notification',{
                    body: msg.value,
                    icon: 'assets/img/favicon.ico',
                    autoClose: false
                  });
                break;
              }
            }
        })
      },1000)


    }
    public isMap(){
        // console.log(this.location.prepareExternalUrl(this.location.path()));
        if(this.location.prepareExternalUrl(this.location.path()) == '#/maps/fullscreen'){
            return true;
        }
        else {
            return false;
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }

    showNotification(from, align, data, mode){
        var type = [mode];
        var color = Math.floor((Math.random() * 4) + 1);
        $.notify({
            icon: "ti-gift",
            message: data
          },{
              type: mode,
              timer: 2000,
              placement: {
                  from: from,
                  align: align
              },
              template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
          });
        }

}
