import { Injectable,Injector  } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {LoginService} from '../Services/login.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { observable, Observable, Subscriber, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{
  token = this.LocalStorage.retrieve('token');
  ldap = this.LocalStorage.retrieve('ldap');
  role = this.LocalStorage.retrieve('role');
  user = this.LocalStorage.retrieve('user');
  constructor(private injector: Injector, private LocalStorage : LocalStorageService, private LoginService : LoginService, private Router : Router) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    let authService = this.injector.get(LoginService)
    let tokenizedReq = request.clone({
      setHeaders: {
        Authorization : `Bearer ${authService.getToken()}`
      }
    })
    return next.handle(tokenizedReq).pipe(
      catchError((err, caught)=>{
        if (err.status === 401){
          this.handleAuthError();
          return of(err);
        }
        throw err;
      })
    );
  }

  private handleAuthError() {
    this.LocalStorage.clear();
    this.Router.navigate(['/login']);
    window.location.reload();
  }

}
