import { Routes } from '@angular/router';
import { AuthGuardGuard } from './auth-guard.guard';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { LoginComponent } from './login/login.component'; 

export const AppRoutes: Routes = [{
    //     path: '',
    //     redirectTo: 'login',
    //     pathMatch: 'full',
    //   },{
        path: 'panel',
        component: AdminLayoutComponent,
        canActivate : [AuthGuardGuard],
        children: [{
            path: '',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
        },{
            path: 'components',
            loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
        },{
            path: 'forms',
            loadChildren: () => import('./forms/forms.module').then(m => m.Forms)
        },{
            path: 'tables',
            loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
        },{
            path: 'maps',
            loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
        },{
            path: 'charts',
            loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
        },{
            path: 'calendar',
            loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
        },{
            path: 'tickets',
            loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule)
        },{
            path: 'quarter',
            loadChildren: () => import('./quarter/quarter.module').then(m => m.QuarterModule)
        },{
            path: 'administration',
            loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
        },{
            path: 'reports',
            loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
        },{
            path: '',
            loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
        },{
            path: '',
            loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
        },{
            path: '',
            loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
        },{
            path: '',
            loadChildren: () => import('./approvals/approvals.module').then(m => m.ApprovalsModule)
        },{
            path: '',
            loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule)
        },{
            path: '',
            loadChildren: () => import('./customfield/customfield.module').then(m => m.CustomfieldModule)
        },{
            path: '',
            loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
        },{
            path: '',
            loadChildren: () => import('./newproject/newproject.module').then(m => m.NewprojectModule)
        },{
            path: '',
            loadChildren: () => import('./projectstatus/projectstatus.module').then(m => m.ProjectstatusModule)
        }]
        },
        {path: 'login', component: LoginComponent},
        {path: '**', component: LoginComponent}
];
