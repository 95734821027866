import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoginService } from '../Services/login.service';
import { Router } from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import { LstorageService } from 'app/Services/lstorage.service';
import * as webNotification from 'simple-web-notification';

declare var $:any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  uname : String;
  pwd : String;
  head = [];
  manager = [];
  supervisor = [];
  member = [];
  datafetch : String[];

  constructor(private Spinner: NgxSpinnerService, private Storage : LocalStorageService, private Router: Router, private SLoginService: LoginService, private LStorage : LstorageService) { }

  ngOnInit(): void {
    webNotification.requestPermission(function onRequest(granted) {
      if (granted) {
          console.log('Desktop Notifications Enabled.');
      } else {
          console.log('Desktop Notifications Not Enabled.');
          setTimeout(()=>{
            alert("Please enable Notifications from browser to enable Desktop Notifications.");
          },500)
      }
    });
  }

  loginUser(event){
    event.preventDefault();
    this.Spinner.show();
    const target = event.target;
    const username = target.querySelector('#uname').value;
    const password = target.querySelector('#pwd').value;
    try{
      this.SLoginService.loginUser(username, password).subscribe((data:any)=>{
        //console.log(data)
        if(data.success){
          this.Storage.store("ldap", username);
          this.Storage.store("user", data.displayname);
          this.Storage.store("token", data.token);
          this.Storage.store("role", data.role);
          this.LStorage.ConfigureAccess(data.displayname)
          setTimeout(()=>{
            this.Spinner.hide();
            //this.Router.navigate(['panel/dashboard']);
          },1000)
        }else{
          this.Spinner.hide();
          this.showNotification('bottom','right', 'Wrong Credentials');
        }
      })
    }catch{
      this.Spinner.hide();
      this.showNotification('bottom','right', 'API Error');
    }
    
  }

  showNotification(from, align, data){
    var type = ['danger'];
    var color = Math.floor((Math.random() * 4) + 1);
    $.notify({
        icon: "ti-gift",
        message: data
      },{
          type: 'danger',
          timer: 2000,
          placement: {
              from: from,
              align: align
          },
          template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
      });
    }
  }
