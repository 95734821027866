import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { GenericService } from 'app/Services/generic.service';
import { LoginService } from 'app/Services/login.service';
import { LocalStorageService } from 'ngx-webstorage';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    admin: boolean;
    // icon: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [{
        path: 'dashboard',
        title: 'Dashboard',
        type: 'link',
        icontype: 'nc-icon nc-bank',
        admin: false 
    },{
        path: 'approvals',
        title: 'Approvals',
        type: 'link',
        icontype: 'nc-icon nc-check-2',
        admin: false
    },{
        path: 'teams',
        title: 'Manage Department',
        type: 'link',
        icontype: 'nc-icon nc-vector',
        admin: false
    },{
        path: 'customfield',
        title: 'Custom Fields',
        type: 'link',
        icontype: 'nc-icon nc-align-left-2',
        admin: true
    },{
        path: 'tickets',
        title: 'Tickets',
        type: 'sub',
        collapse: 'tickets',
        icontype: 'nc-icon nc-tag-content',
        admin: false,
        children: [
            {path: 'createticket', title: 'Create Ticket', ab:'Crt'},
            {path: 'opentickets', title: 'Open Tickets', ab:'Ot'},
            {path: 'closetickets', title: 'Close Tickets', ab:'Clt'},
        ]
    },{
        path: 'quarter',
        title: 'Quarter',
        type: 'sub',
        collapse: 'quarter',
        icontype: 'nc-icon nc-bullet-list-67',
        admin: false,
        children: [
            {path: 'backlog', title: 'BackLog', ab:'B'},
            {path: 'initialize', title: 'Initialize Quarter', ab:'Iq'},
            {path: 'currentquarter', title: 'Current Quarter', ab:'Cq'},
            {path: 'previousquarter', title: 'Previous Quarter', ab:'Pq'},
        ]
    },{
        path: 'newproject',
        title: 'New Project',
        type: 'link',
        icontype: 'nc-icon nc-tile-56',
        admin: false

    },{
        path: 'projectstatus',
        title: 'Project Status',
        type: 'link',
        icontype: 'nc-icon nc-layout-11',
        admin: false

    },{
        path: 'reports',
        title: 'Reports',
        type: 'sub',
        collapse: 'reports',
        icontype: 'nc-icon nc-sound-wave',
        admin: false,
        children: [
            {path: 'ticketreports', title: 'Tickets Report', ab:'Tr'}
        ]
    },{
        path: 'administration',
        title: 'Admin',
        type: 'sub',
        collapse: 'administration',
        icontype: 'nc-icon nc-touch-id',
        admin: true,
        children: [
            {path: 'assignroles', title: 'Assign Roles', ab:'ARO'},
            {path: 'adddepartment', title: 'Add Department', ab:'AD'},
            {path: 'addrelation', title: 'Add Relations', ab:'ARE'},
            {path: 'notification', title: 'Manage Notifications', ab:'N'}
        ]
    }
    // ,{
    //     path: 'components',
    //     title: 'Components',
    //     type: 'sub',
    //     collapse: 'components',
    //     icontype: 'nc-icon nc-layout-11',
    //     admin: false,
    //     children: [
    //         {path: 'buttons', title: 'Buttons', ab:'B'},
    //         {path: 'grid', title: 'Grid System', ab:'GS'},
    //         {path: 'panels', title: 'Panels', ab:'P'},
    //         {path: 'sweet-alert', title: 'Sweet Alert', ab:'SA'},
    //         {path: 'notifications', title: 'Notifications', ab:'N'},
    //         {path: 'icons', title: 'Icons', ab:'I'},
    //         {path: 'typography', title: 'Typography', ab:'T'}
    //     ]
    // }
    // ,{
    //     path: 'forms',
    //     title: 'Forms',
    //     type: 'sub',
    //     collapse: 'forms',
    //     icontype: 'nc-icon nc-ruler-pencil',
    //     admin: false,
    //     children: [
    //         {path: 'regular', title: 'Regular Forms', ab:'RF'},
    //         {path: 'extended', title: 'Extended Forms', ab:'EF'},
    //         {path: 'validation', title: 'Validation Forms', ab:'VF'},
    //         {path: 'wizard', title: 'Wizard', ab:'W'}
    //     ]
    // }
    // ,{
    //     path: 'tables',
    //     title: 'Tables',
    //     type: 'sub',
    //     collapse: 'tables',
    //     icontype: 'nc-icon nc-single-copy-04',
    //     admin: false,
    //     children: [
    //         {path: 'regular', title: 'Regular Tables', ab:'RT'},
    //         {path: 'extended', title: 'Extended Tables', ab:'ET'},
    //         {path: 'datatables.net', title: 'Datatables.net', ab:'DT'}
    //     ]
    // }
    // ,{
    //     path: 'maps',
    //     title: 'Maps',
    //     type: 'sub',
    //     collapse: 'maps',
    //     icontype: 'nc-icon nc-pin-3',
    //     admin: false,
    //     children: [
    //         {path: 'google', title: 'Google Maps', ab:'GM'},
    //         {path: 'fullscreen', title: 'Full Screen Map', ab:'FSM'},
    //         {path: 'vector', title: 'Vector Map', ab:'VM'}
    //     ]
    // }
    // ,{
    //     path: 'widgets',
    //     title: 'Widgets',
    //     type: 'link',
    //     icontype: 'nc-icon nc-box',
    //     admin: false

    // }
    // ,{
    //     path: 'charts',
    //     title: 'Charts',
    //     type: 'link',
    //     icontype: 'nc-icon nc-chart-bar-32',
    //     admin: false

    // }
    // ,{
    //     path: 'calendar',
    //     title: 'Calendar',
    //     type: 'link',
    //     icontype: 'nc-icon nc-calendar-60',
    //     admin: false

    // }
    // ,{
    //     path: 'pages',
    //     title: 'Pages',
    //     collapse: 'pages',
    //     type: 'sub',
    //     icontype: 'nc-icon nc-book-bookmark',
    //     admin: false,
    //     children: [
    //         {path: 'timeline', title: 'Timeline Page', ab:'T'},
    //         {path: 'user', title: 'User Page', ab:'UP'},
    //         {path: 'login', title: 'Login Page', ab:'LP'},
    //         {path: 'register', title: 'Register Page', ab:'RP'},
    //         {path: 'lock', title: 'Lock Screen Page', ab:'LSP'}
    //     ]
    // }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];
    isNotMobileMenu(){
        if( window.outerWidth > 991){
            return false;
        }
        return true;
    }

    constructor(private Sanitizer : DomSanitizer, private LoginService : LoginService, private GenericService : GenericService, private LocalStorage : LocalStorageService) { }

    uname:String = this.LocalStorage.retrieve('user');
    ldap = this.LocalStorage.retrieve('ldap');
    role:String = this.LocalStorage.retrieve('role');
    adminrole : boolean = false;
    url : any;
    
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        if(this.role === 'AppAdmin'){
            this.adminrole = true;
        }else{
            this.getProfile();
        }

    }
    ngAfterViewInit(){
    }

    getProfile(){
        this.LoginService.manageProfile('getuser', this.ldap, this.uname, '').subscribe((data)=>{
          if(data){
              if(data[0]["picture_users"] != null){
                this.url = '../assets/profiles/'+data[0]["picture_users"];
              }else{
                this.url = '../assets/profiles/default.jpg';
              }
          }
        })
      }

}
