import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { LoginService } from './Services/login.service';
import { map } from 'rxjs/operators';
import { LstorageService } from './Services/lstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  datafetch = [];
  constructor(private LocalStorage : LocalStorageService, private Router : Router, private LoginService : LoginService, private LStorage : LstorageService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const ldap = this.LocalStorage.retrieve('ldap');
      const token = this.LocalStorage.retrieve('token');
      const role = this.LocalStorage.retrieve('role');
      const user = this.LocalStorage.retrieve('user');
      if(token && token.length > 1){
        //console.log("Validating Session");
      return this.LoginService.validateToken(token, user, ldap ,role).pipe(
        map(e => {
          if (e) {
            this.LStorage.ConfigureAccess(user);
            return true;
          } else {
            //this.LocalStorage.clear();
            this.Router.navigate(['/login']);
          }
        })
      );
    }
    else{
      this.Router.navigate(['/login']);
    }
  }
  
}
