import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';

interface myData{
  success: boolean,
  failure : boolean,
  var: string
}

@Injectable({
  providedIn: 'root'
})
export class GenericService {

  constructor(private Http: HttpClient, private LocalStorage : LocalStorageService) { }

  manageRights(action, id , data1 , data2, data3){
    return this.Http.post('/api/v3/generic/managerights', {
      action : action,
      id : id,
      data1 : data1,
      data2 : data2,
      data3 : data3
    })
  }

  manageProfile(action, uname, dname, email){
    return this.Http.post('/api/v3/generic/manageprofile', {
      action : action,
      uname : uname,
      dname : dname,
      email : email
    })
  }

  manageDepartment(action, data1 , data2){
    return this.Http.post('/api/v3/generic/managedepartment', {
      action : action,
      data1 : data1,
      data2 : data2
    })
  }

  manageDepRelations(action, id , data1 , data2){
    return this.Http.post('/api/v3/generic/managedeprelations', {
      action : action,
      id : id,
      data1 : data1,
      data2 : data2
    })
  }

  manageCustomField(action, id , fname, rname, requ, min, max, rchar, rnum, allowchar, dep){
    return this.Http.post('/api/v3/generic/managecustomfield', {
      action : action,
      id : id,
      fname : fname,
      rname : rname,
      requ : requ,
      min : min,
      max : max,
      rchar : rchar,
      rnum : rnum,
      allowchar : allowchar,
      dep : dep
    })
  }

  updateProfileImage(formData){
    return this.Http.post<myData>('/api/v3/generic/updatepicture', formData ,{headers : new HttpHeaders({'Authorization' : 'Bearer ' + this.LocalStorage.retrieve('token')})})
  }

  fetchProfileDownload(data){
    return this.Http.post('/api/v3/generic/fetchprofiledownload', {
      data:data,
    },{headers : new HttpHeaders({'Authorization' : 'Bearer ' + this.LocalStorage.retrieve('token')}),responseType: 'blob'})
  }

  fetchAttachmentDownload(data){
    return this.Http.post('/api/v3/generic/fetchdownload', {
      data:data,
    },{headers : new HttpHeaders({'Authorization' : 'Bearer ' + this.LocalStorage.retrieve('token')}),responseType: 'blob'})
  }

  getNotifications(){
    return this.Http.get('/api/v3/generic/getnotifications')
  }

}
