import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {LocalStorageService} from 'ngx-webstorage';
import { LoginService } from './login.service';

declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class LstorageService {

  head = [];
  manager = [];
  supervisor = [];
  member = [];
  rightset : boolean = false;
  datafetch = [];
  deps = [];
  globaldeps : string;

  constructor(private LocalStorage : LocalStorageService, private SLoginService : LoginService, private Router : Router) { }

  setAccess(rights, department){
    if(rights == 'Manager'){
      this.manager.push(department);
      this.deps.push(department);
      this.rightset = true;
    }
    if(rights == 'Head'){
      this.head.push(department);
      this.deps.push(department);
      this.rightset = true;
    }
    if(rights == 'Supervisor'){
      this.supervisor.push(department);
      this.deps.push(department);
      this.rightset = true;
    }
    if(rights == 'Member'){
      this.member.push(department);
      this.deps.push(department);
      this.rightset = true;
    }
  }

  getAccess(rights){
    if(rights == 'Manager'){
      return this.manager;
    }
    if(rights == 'Head'){
      return this.head;
    }
    if(rights == 'Supervisor'){
      return this.supervisor;
    }
    if(rights == 'Member'){
      return this.member;
    }
  }

  rightsConfigured(){
    return this.rightset;
  }

  async ConfigureAccess(username){
    //console.log(username);
    // if(username == 'supportms'){
    //   this.Router.navigate(['panel/dashboard']);
    //   return;
    // }
    this.manager = [];
    this.head = [];
    this.supervisor = [];
    this.member = [];
    this.deps = [];
    // this.LocalStorage.clear("manager");
    // this.LocalStorage.clear("head");
    // this.LocalStorage.clear("supervisor");
    // this.LocalStorage.clear("member");
    // this.LocalStorage.clear("headdeps");
    // this.LocalStorage.clear("managerdeps");
    // this.LocalStorage.clear("departments");
    // this.LocalStorage.clear("globaldeps");
    this.SLoginService.getRights(username).subscribe((data:any)=>{
      if(data){
        //console.log(data);
        this.datafetch = data as String[];
        for (var i = 0; i < this.datafetch.length; i++){
          if(this.datafetch[i]["role_rights"] === 'Manager'){
            this.setAccess('Manager', this.datafetch[i]["department_rights"]);
          }
          if(this.datafetch[i]["role_rights"] === 'Supervisor'){
            this.setAccess('Supervisor', this.datafetch[i]["department_rights"]);
          }
          if(this.datafetch[i]["role_rights"] === 'Head'){
            this.setAccess('Head', this.datafetch[i]["department_rights"]);
          }
          if(this.datafetch[i]["role_rights"] === 'Member'){
            this.setAccess('Member', this.datafetch[i]["department_rights"]);
          }
          if(this.datafetch[i]["primary_rights"] === 1){
            this.LocalStorage.store("Primary" , this.datafetch[i]["department_rights"]);
          }
          if(i == this.datafetch.length-1){
            this.LocalStorage.store("Manager" , this.manager);
            this.LocalStorage.store("Head" , this.head);
            this.LocalStorage.store("Supervisor" , this.supervisor);
            this.LocalStorage.store("Member" , this.member);
            this.LocalStorage.store("Departments" , this.deps);
            this.populateGlobaldeps();
            this.populateHeaddeps();
            this.populateManagerdeps();
            this.Router.navigate(['panel/dashboard']);
          }
        }
      }else{
        console.log("No Rights Detected");
        this.showNotification('bottom','right', 'No Rights Detected, Please contact your Line Manager for Rights', 'danger');
        this.Router.navigate(['/login']);
      }
    })
    
  }

  populateGlobaldeps(){
    //console.log(this.deps);
    var data = '';
    for(var i = 0; i < this.deps.length; i ++){
      //console.log(data);
      if(i == this.deps.length-1){
        //console.log(this.deps.length, i)
        data += this.deps[i]
        this.LocalStorage.store("globaldeps" , data);
      }else{
        data += this.deps[i]+'|'
      }
    }
  }

  populateHeaddeps(){
    //console.log(this.deps);
    var data = '';
    for(var i = 0; i < this.head.length; i ++){
      //console.log(data);
      if(i == this.head.length-1){
        //console.log(this.deps.length, i)
        data += this.head[i]
        this.LocalStorage.store("headdeps" , data);
      }else{
        data += this.head[i]+'|'
      }
    }
  }

  populateManagerdeps(){
    //console.log(this.deps);
    var data = '';
    for(var i = 0; i < this.manager.length; i ++){
      //console.log(data);
      if(i == this.manager.length-1){
        //console.log(this.deps.length, i)
        data += this.manager[i]
        this.LocalStorage.store("managerdeps" , data);
      }else{
        data += this.manager[i]+'|'
      }
    }
  }

  showNotification(from, align, data, mode){
    var type = [mode];
    var color = Math.floor((Math.random() * 4) + 1);
    $.notify({
        icon: "ti-gift",
        message: data
      },{
          type: mode,
          timer: 2000,
          placement: {
              from: from,
              align: align
          },
          template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
      });
    }

}
