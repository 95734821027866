import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {LocalStorageService} from 'ngx-webstorage';

interface myData{
  success: boolean,
  failure : boolean,
  var: string
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private Http: HttpClient, private LocalStorage : LocalStorageService) { }

  loginUser(username, password){
    return this.Http.post<myData>('/api/v3/auth_login/', {
      username:username,
      password:password
    })
  }
  
  validateToken(token, user, ldap,role){
    return this.Http.post('/api/v3/auth_login/validate', {
      token:token,
      user:user,
      ldap:ldap,
      role:role
    })
  }

  getRights(data1){
    return this.Http.post('/api/v3/auth_login/getrights', {
      data1 : data1
    })
  }
  
  getToken(){
    return this.LocalStorage.retrieve('token');
  }
  
  manageProfile(action, uname, dname, email){
    return this.Http.post('/api/v3/profile/manageprofile', {
      action : action,
      uname : uname,
      dname : dname,
      email : email
    })
  }

}
